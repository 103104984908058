.HistoryReccer {
  max-width: 1450px;
  margin: 0 auto;
  margin-top: 50px;

  .HomeReccer-main,
  .HomeReccer-sub {
    div {
      .btn-action {
        padding: 4px 6px;
      }

      .btn-action.btn-calculator,
      .btn-action.btn-view,
      .btn-action.btn-delete {
        &:hover {
          background-color: rgba(204, 204, 204, 0.5);
        }
      }

      div {
        .ant-table-thead .ant-table-cell {
          background-color: rgb(207, 232, 224);
          border-radius: 0 !important;
        }
      }
    }
  }
}

.HomeReccer-sub {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .HomeReccer {
    div {
      .btn-action {
        padding: 4px 4px;
      }
    }
  }

  .HomeReccer-main {
    display: none;
  }

  .HomeReccer-sub {
    display: block;
  }
}