.ForgotPassword {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url("../../../../assets/images/loginBg.jpg");
  .Form {
    width: 400px;
    max-width: 400px;
    border-radius: 20px;
    background-color: white;
    padding: 25px 65px 50px 65px;
    margin-bottom: 70px;
    position: relative;

    .input {
      border-radius: 0;
      font-family: Quicksand;
      font-weight: 500;
      font-size: 15px;
      color: black;
      line-height: 25px;
      padding-left: 10px;
      background-color: white;

      min-height: 38px;
      border: 1px solid;
      border-color: black;
    }

    .button {
      height: 38px;
      padding-right: 0px;
      padding-left: 10px;
      background-color: #00B14F;

      font-family: Quicksand;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      transition: 0.2s all
    }

    .button:hover {
      background-color: #1ab961 !important;
    }

    .header {
      font-family: Quicksand;
      text-align: center;
      margin-bottom: 0px !important;
    }

    .linkSmall {
      font-family: Quicksand;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
    }
    .goBack {
      display: flex;
      justify-content: space-evenly;
      border: none;
      outline: none;
      margin-bottom: 10px;
    }
  }
  .Loading {
    margin-right: 8px;
    display: inline-block;
  }
}
