
.Layout {
  padding: 0;
  height: 100%;
  max-width: 100vw;
  position: relative;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  
  .Content {
    position: relative;
    max-height: 100%;
    min-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .Content > div:nth-child(1), Content > main:nth-child(1) {
    min-height: 36rem;
  }

  .content-container {
    padding: 0 5rem;
  }

  .internal-layout {
    max-height: 100vh;
  }

  .internal-content {
    max-height: 100%;
  }

  .Footer {
    padding: 0;
    min-height: 10vh;
  }

  .HasHero {
    width: 100%;
  }

  .HasHero span {
    font-weight: 900;
    color: white;
    font-size: 2rem;
  }

  .HasHero h1 {
    color: white;
  }

  figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: initial !important;
  }

  .ContentWrapper {
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
