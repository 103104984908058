.CandidateInterviewHistory{
    min-width: 720px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 25px!important;
    margin-top: 15px;
    min-height: 36rem;
    .CandidateInterviewHistoryContent{
        display: flex;
        box-sizing: border-box;
        width: 100%;
        .HistoryTable{ 
            display: flex;
            box-sizing: border-box;
            border: 1px solid ;
        }
    }
    .statistic{
        display: flex;
        gap:25px;
        position: relative;
        .interviewStatistic,.applicationsStatistic{
            width: calc(50% - 12.5px);
        }
    }
    .table{
        background-color: white;
        border-radius: 8px;
    }
    .loading {
        width: 100%;
        height: 200px;
    }
}