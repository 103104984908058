.Signup {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url("../../../../assets/images/loginBg.jpg");

  .Form {
    width: 400px;
    max-width: 400px;
    gap: 15px 0;
    border-radius: 20px;
    background-color: white;
    align-items: center;
    padding: 3em 65px 7em 65px;
    margin-bottom: 70px;
    position: relative;

    .header {
      display: inline-block;
      width: 100%;
      font-family: Quicksand;
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 10px;
      text-align: center;
    }

    .header span {
      font-family: "Quicksand";
      font-size: 25px;
      font-weight: 700;
    }

    .input {
      border-radius: 10px;
      font-family: Quicksand;
      font-weight: 500;
      font-size: 15px;
      color: black;
      line-height: 25px;
      padding-left: 10px;
      background-color: white;

      min-height: 38px;
      border: 1px solid;
      border-color: black;
    }

    input:checked {
      border: 2px solid;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }

    .button {
      height: 38px;
      padding-right: 0px;
      padding-left: 10px;
      background-color: #00B14F;
      border-radius: 10px;

      font-family: Quicksand;
      font-weight: 600;
      font-size: 18px;
      color: white;
      line-height: 25px;
      transition: 0.2s all
    }

    .button:hover {
      background-color: #1ab961 !important;
    }

    .link {
      font-family: Quicksand;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 25px;
      color: black !important;
    }

    .link:hover {
      opacity: 0.6;
    }

    .linkSmall {
      font-family: Quicksand;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
    }

    .center {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .icon {
      color: coral;
      font-size: 20px;
    }
    .goBack {
      display: flex;
      justify-content: space-evenly;
      border: none;
      outline: none;
      margin-bottom: 10px;
    }
  }
  .Loading {
    margin-right: 8px;
    display: inline-block;
  }
}
