.Event {
  display: flex;
  justify-content: center;
  padding: 0 80px;
  font-size: 1rem;
  margin: 30px 0;
  --edit-color: rgba(43, 6, 174, 0.992);

  .event-image {
    display: flex;
    align-items: stretch;
    width: 360px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .avatar-view {
      width: 100%;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }

  .event-content {
    position: relative;
    width: 100%;
    max-width: 1240px;
    padding: 0 40px;
    border: 1px solid #e5e5e5;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;

    .event-name {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 168px;

      div {
        color: rgba(252, 130, 24, 0.904);
        font-weight: bold;

        div {
          span {
            color: var(--edit-color);
          }
        }
      }
    }

    .event-information.information-main {
      display: flex;
      justify-content: space-between;

      ul {
        display: grid;
        padding-left: 0;
        padding-top: 30px;
        padding-bottom: 10px;
        list-style-type: none;

        li {
          height: 50px;

          div {
            div {
              span {
                svg {
                  color: var(--edit-color);
                }
              }

              div {
                font-size: 1rem;

                input {
                  font-size: 1rem;
                  font-weight: bold;
                  font-family: system-ui, -apple-system, BlinkMacSystemFont,
                    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                    "Helvetica Neue", sans-serif;
                  color: #000000e0;

                  svg {
                    color: var(--edit-color);
                  }

                  &:hover {
                    cursor: default;
                  }
                }

                span {
                  font-weight: bold;
                  color: #000000e0;

                  svg {
                    color: var(--edit-color);
                  }

                  &:hover {
                    cursor: default;
                  }
                }
              }
            }
          }
        }
      }
    }

    .event-information.information-sub {
      display: none;

      ul {
        display: grid;
        padding-left: 0;
        padding-top: 15px;
        padding-bottom: 10px;
        list-style-type: none;

        li {
          height: 40px;

          div {
            margin: 0;

            div {
              span {
                svg {
                  color: var(--edit-color);
                }
              }

              div {
                font-size: 1rem;

                input {
                  font-size: 1rem;
                  font-weight: bold;
                  font-family: system-ui, -apple-system, BlinkMacSystemFont,
                    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                    "Helvetica Neue", sans-serif;
                  color: #000000e0;

                  svg {
                    color: var(--edit-color);
                  }

                  &:hover {
                    cursor: default;
                  }
                }

                span {
                  font-weight: bold;
                  color: #000000e0;

                  svg {
                    color: var(--edit-color);
                  }

                  &:hover {
                    cursor: default;
                  }
                }
              }
            }
          }
        }
      }
    }

    .event-description {
      padding: 20px 0;
      text-align: justify;

      div {
        margin: 0;

        div {
          span {
            svg {
              color: var(--edit-color);
            }
          }
        }
      }
    }

    .event-rule,
    .event-benefit {
      padding: 20px 0;

      ul {
        text-align: justify;
        padding-top: 10px;
        padding-left: 20px;
        color: #000000e0;

        li {
          div {
            margin: 0;
          }
        }

        div {
          div {
            span {
              svg {
                color: var(--edit-color);
              }
            }
          }
        }
      }
    }

    .event-contact {
      padding: 20px 0;
      margin-bottom: 80px;

      table {
        tbody {
          tr {
            td {
              width: 40%;

              div {
                margin: 0;

                div {
                  span {
                    svg {
                      color: var(--edit-color);
                    }
                  }
                }
              }

              &:first-child {
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    p {
      font-weight: bold;
      font-size: 1.1rem;
    }

    .btn-drop {
      position: absolute;
      right: 30px;
      bottom: 30px;

      .btn-cancel {
        margin-right: 15px;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .btn-exit {
      position: absolute;
      top: 10px;
      right: 20px;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  hr {
    margin: 0 auto;
    width: 80%;
    color: #dadada;
  }
}

@media only screen and (max-width: 1500px) {
  .Event {
    .event-image {
      width: 240px;
    }

    .event-content {
      .event-information.information-main {
        display: none;
      }

      .event-information.information-sub {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .Event {
    .event-image {
      display: none;
    }

    .event-content {
      .event-information.information-main {
        display: none;
      }

      .event-information.information-sub {
        display: block;
      }

      .btn-drop {
        left: 0 !important;
        right: 0 !important;
        text-align: center;
      }
    }
  }
}