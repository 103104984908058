.card{
    .ant-card-head-title{
        font-weight: 600;
        margin-bottom: 0.5rem;
        text-align: center;
    }
    .ant-card-head{
        padding: 0;
    }
    .ant-tabs-nav-list{
        width: 100%;
        justify-content: center;
        .ant-tabs-tab{
            width: 50%;
            text-align: center;
            margin: 0;
            justify-content: center;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: #52c41a!important;
                font-weight: 600;
            }
        }
    
        .ant-tabs-ink-bar {
            height: 5px;
            background-color: #52c41a !important;
          }
        
    
}