.UserInfo {
    width: 100%;
    max-width: 1300px;
    margin: 30px 0;
    padding-bottom: 40px;
    border-radius: 25px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    --avatar-width: 100px;

    .avatar-wrapper {
        display: flex;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid #d2d4d7fd;

        .avatar {
            position: relative;
            width: var(--avatar-width);
            height: var(--avatar-width);

            .avatar-view {
                height: var(--avatar-width);
                width: var(--avatar-width);
                border: 3px solid #0af;
                border-radius: 50%;
                transition: background ease-out 200ms;
            }

            #btn-upload {
                border-radius: 50%;
                border: none;
                cursor: pointer;
                background-color: #08f;
                box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
                    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
                transition: background-color ease-out 120ms;
                position: absolute;
                right: -10%;
                bottom: 0;

                &:hover {
                    background-color: rgba(129, 128, 128, 0.9);
                }
            }
        }

        input[type="file"] {
            display: none;
        }

        .main-info-wrapper {
            text-align: left;
            padding-left: 32px;
            padding-bottom: 8px;
            line-height: 30px;

            #user-role-mobile {
                display: none;
            }
        }

        #user-role-pc {
            font-size: 1rem;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            border: 1px solid #ccc;
            border-radius: 10px;
        }
    }

    .info-content {
        position: relative;

        input {
            width: 90%;
            height: 40px;
            font-size: 1rem;
            padding: 8px 15px;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
        }

        .btn-edit-wrapper {
            position: absolute;
            top: 20px;
            right: 20px;

            button {
                &:hover {
                    opacity: 0.7;
                    border: none;
                }
            }
        }

        .select-country {
            div {
                height: 40px;

                span {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    font-size: 1rem;
                }
            }
        }

        .info-password {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100% !important;
            height: 40px !important;
            gap: 0px !important;

            div:first-child {
                width: 100%;

            }

            #btn-show-password {
                padding: 0;
                margin: 0;
                width: 50px;

                &:hover {
                    background-color: #dedede;
                    color: black;
                    border: none;
                }
            }

            input {
                color: #696969;
            }
        }

        // .info-skill-wrapper {
        //     display: grid;
        //     justify-content: center;
        //     grid-gap: 15px;
        //     grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));

        //     .info-skill {
        //         position: relative;
        //         display: flex;
        //         align-items: center;
        //         width: 100%;
        //         height: 40px;
        //         font-weight: bold;
        //         border: 1px solid #ccc;
        //         border-radius: 5px;
        //         margin-right: 20px;
        //         padding: 20px;

        //         .btn-delete-skill {
        //             position: absolute;
        //             right: 0;
        //             height: 100%;
        //             border: none;
        //             border-left: 1px solid #ccc;
        //             border-top-left-radius: 0px;
        //             border-bottom-left-radius: 0px;

        //             &:hover {
        //                 background-color: rgba(231, 227, 227, 0.951);
        //             }
        //         }
        //     }
        // }
    }
}

@media only screen and (max-width: 768px) {
    .UserInfo {
        .avatar-wrapper {
            .main-info-wrapper {
                padding-bottom: 4px;
                line-height: 25px;

                #user-role-mobile {
                    display: block;
                    text-align: center;
                    margin-top: 5px;
                    width: 80px;
                }
            }

            #user-role-pc {
                display: none;
            }
        }
    }
}