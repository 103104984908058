.AdminDashboard {
  padding: 3rem;
  .table {
    height: 100%;
    margin: 0.5rem;
    background: #fff;
    padding: 1rem;
    border-radius: 0.6rem;
  }
}
