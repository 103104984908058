.CandidateEditResume {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #dee2e6;
  height: 90vh;

  .loading {
    font-size: 3rem;
  }

  .flex {
    display: flex;
  }

  .w-full {
    width: 100%;
  }

  .justify-evenly {
    justify-content: space-evenly;
  }

  .gap-8 {
    gap: 8px;
  }
}
